import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
import HubspotForm from "react-hubspot-form"

const AireReport = ({ location }) => {
  return (
    <Layout location={location}>
      <Helmet>
        <title>
          Quantifying R&R Programs for Measurable Results|1st AIRe Benchmark
          Report (UK): 2023 -2024
        </title>

        <meta
          name="description"
          content="A comprehensive report unveiling the impact of Employee Rewards and Recognition Programmes across diverse industries in United Kingdom, evaluated through the lens of AIRe framework."
        />
        <meta
          property="twitter:title"
          content="R&R Benchmarking Report - UK | AIRe Report"
        />

        <meta
          property="twitter:description"
          content="A comprehensive report unveiling the impact of Employee Rewards and Recognition Programmes across diverse industries in United Kingdom, evaluated through the lens of AIRe framework."
        />

        <meta property="og:type" content="website" />

        <meta
          property="og:url"
          content="https://www.vantagecircle.com/aire-benchmarking-report-uk/"
        />

        <meta property="og:site_name" content="Vantage Circle" />
        <meta
          property="og:title"
          content="R&R Benchmarking Report - UK | AIRe Report"
        />

        <meta
          property="og:description"
          content="A comprehensive report unveiling the impact of Employee Rewards and Recognition Programmes across diverse industries in United Kingdom, evaluated through the lens of AIRe framework."
        />
        <link
          rel="canonical"
          href="https://www.vantagecircle.com/aire-benchmarking-report-uk/"
        ></link>
      </Helmet>

      <section className="bg-gray-newGrayDark mx-auto py-10 md:py-20 mb-0 md:mb-28 relative">
        <div className="container max-w-5xl sm:text-center px-6 sm:px-6 lg:px-6">
          <h1 className="homepage-heading text-center">
          Quantifying R&R Programmes for Measurable Results |1st AIR<sup>e</sup> Benchmarking Report (UK): 2023-2024
          </h1>
          <p className="section-subtitle text-center">A comprehensive report unveiling the impact of Employee Rewards and Recognition Programmes across diverse industries, evaluated through the lens of AIR<sup>e</sup> framework.</p>
          <div className="mt-5 sm:mt-8 md:mt-10 flex justify-center">
            <div className="">
              <a href="#download-now" className="vc-new-orange-btn-rounded-full">
                Download now
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="ebook-carousel container text-center max-w-4xl md:-mt-36 mb:mb-20 px-7">
        <CarouselProvider
          className="relative mx-auto"
          naturalSlideWidth={110}
          naturalSlideHeight={80}
          totalSlides={4}
        >
          <Slider className="">
            <Slide index={0}>
              <img
                src="https://res.cloudinary.com/vantagecircle/image/upload/v1666853042/gatsbycms/uploads/2023/09/uk-aire-cover.png"
                alt="Slide1"
              />
            </Slide>
            <Slide index={1}>
              <img
                src="https://res.cloudinary.com/vantagecircle/image/upload/v1666853042/gatsbycms/uploads/2023/09/uk-aire-1.png"
                alt="Slide2"
              />
            </Slide>
            <Slide index={2}>
              <img
                src="https://res.cloudinary.com/vantagecircle/image/upload/v1666853042/gatsbycms/uploads/2023/09/uk-aire-2.png"
                alt="Slide3"
              />
            </Slide>
            <Slide index={3}>
              <img
                src="https://res.cloudinary.com/vantagecircle/image/upload/v1666853042/gatsbycms/uploads/2023/09/uk-aire-3.png"
                alt="Slide4"
              />
            </Slide>
          </Slider>
          <ButtonBack className="shadow eb-carousel-btn">
            <svg
              className="mx-auto text-purple-200"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
            </svg>
          </ButtonBack>
          <ButtonNext className="shadow eb-carousel-btn">
            <svg
              className="mx-auto text-purple-200"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z" />
            </svg>
          </ButtonNext>
          <DotGroup />
        </CarouselProvider>
      </section>
      <section
        id="download-now"
        className="container max-w-7xl py-10 md:py-20 mx-auto px-6 mt-12"
      >
        <div className="">
          <div className="md:flex">
            <div className="card-1 w-full md:w-1/2 mb-10 md:mb-0 lg:mt-0">
              <h2 className="mb-5 sm:mb-10 text-3xl text-gray-900 sm:text-4xl">
                You will get to learn
              </h2>
              <ul className="form-bullets list-inside orangebullets">
                <li>UK’s employee rewards and recognition landscape with valuable insights from prominent companies in the UK.</li>
                <li> Average AIR<sup>e</sup> score across Recognition programs</li>
                <li>Organisational size and Industry insights and effective strategies for R&R Programs</li>
                <li>Best practices of R&R Programmes for diverse industries provided by the industry experts</li>
                <li>Data-driven decision-making for organizational excellence and leveraging the strategies</li>
                <li> Insights from the R&R Survey across 150+ organization</li>
              </ul>
            </div>
            <div className="card-1 w-full md:w-1/2 text-gray-900 rounded shadow-lg p-8 lg:p-10 md:ml-28">
              <div className="form-section">
                <h2 className="mt-2 mb-8 text-2xl text-center text-gray-900 sm:text-3xl">
                  Fill up to download your copy now
                </h2>
                <HubspotForm
                  portalId="6153052"
                  formId="3e27c003-c13b-45fc-ba10-5cd32ff97040"
                  onSubmit={() => console.log("Submit!")}
                  onReady={form => console.log("Form ready!")}
                  loading={<div>Loading...</div>}
                />
                {/* <div className="notetext text-center font-normal text-sm">We safeguard your personal information in accordance with our <a href="/privacy-policy/" target="_blank">Privacy Policy</a></div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default AireReport
